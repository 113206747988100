@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: bold;
  src: url("./IranSans/IRANSANS_BOLD.TTF") format("truetype"),
    url("./IranSans/IRANSANS_BOLD.woff") format("woff");
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  src: url("./IranSans/IRANSANS_MEDIUM.TTF") format("truetype"),
    url("./IranSans/IRANSANS_MEDIUM.woff") format("woff");
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  src: url("./IranSans/IRANSANS_LIGHT.TTF") format("truetype"),
    url("./IranSans/IRANSANS_LIGHT.woff") format("woff");
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 200;
  src: url("./IranSans/IRANSANS_ULTRALIGHT.TTF") format("truetype"),
    url("./IranSans/IRANSANS_ULTRALIGHT.woff") format("woff");
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  src: url("./IranSans/IRANSANS.TTF") format("truetype"),
    url("./IranSans/IRANSANS.woff") format("woff");
}
@font-face {
  font-family: "ITC Avant Garde Gothic Std Extra Light";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Avant Garde Gothic Std Extra Light"),
    url("./ITC/ITCAvantGardeStdXLt.woff") format("woff");
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Extra Light Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Avant Garde Gothic Std Extra Light Condensed"),
    url("./ITC/ITCAvantGardeStdXLtCn.woff") format("woff");
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Extra Light Condensed Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Avant Garde Gothic Std Extra Light Condensed Oblique"),
    url("./ITC/ITCAvantGardeStdXLtCnObl.woff") format("woff");
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Extra Light Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Avant Garde Gothic Std Extra Light Oblique"),
    url("./ITC/ITCAvantGardeStdXLtObl.woff") format("woff");
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Book";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Avant Garde Gothic Std Book"),
    url("./ITC/ITCAvantGardeStdBk.woff") format("woff");
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Book Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Avant Garde Gothic Std Book Condensed"),
    url("./ITC/ITCAvantGardeStdBkCn.woff") format("woff");
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Book Condensed Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Avant Garde Gothic Std Book Condensed Oblique"),
    url("./ITC/ITCAvantGardeStdBkCnObl.woff") format("woff");
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Book Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Avant Garde Gothic Std Book Oblique"),
    url("./ITC/ITCAvantGardeStdBkObl.woff") format("woff");
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Medium";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Avant Garde Gothic Std Medium"),
    url("./ITC/ITCAvantGardeStdMd.woff") format("woff");
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Medium Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Avant Garde Gothic Std Medium Condensed"),
    url("./ITC/ITCAvantGardeStdMdCn.woff") format("woff");
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Medium Condensed Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Avant Garde Gothic Std Medium Condensed Oblique"),
    url("./ITC/ITCAvantGardeStdMdCnObl.woff") format("woff");
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Medium Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Avant Garde Gothic Std Medium Oblique"),
    url("./ITC/ITCAvantGardeStdMdObl.woff") format("woff");
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Demi";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Avant Garde Gothic Std Demi"),
    url("./ITC/ITCAvantGardeStdDemi.woff") format("woff");
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Demi Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Avant Garde Gothic Std Demi Condensed"),
    url("./ITC/ITCAvantGardeStdDemiCn.woff") format("woff");
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Demi Condensed Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Avant Garde Gothic Std Demi Condensed Oblique"),
    url("./ITC/ITCAvantGardeStdDemiCnObl.woff") format("woff");
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Demi Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Avant Garde Gothic Std Demi Oblique"),
    url("./ITC/ITCAvantGardeStdDemiObl.woff") format("woff");
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Bold";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Avant Garde Gothic Std Bold"),
    url("./ITC/ITCAvantGardeStdBold.woff") format("woff");
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Bold Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Avant Garde Gothic Std Bold Condensed"),
    url("./ITC/ITCAvantGardeStdBoldCn.woff") format("woff");
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Bold Condensed Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Avant Garde Gothic Std Bold Condensed Oblique"),
    url("./ITC/ITCAvantGardeStdBoldCnObl.woff") format("woff");
}

@font-face {
  font-family: "ITC Avant Garde Gothic Std Bold Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Avant Garde Gothic Std Bold Oblique"),
    url("./ITC/ITCAvantGardeStdBoldObl.woff") format("woff");
}
