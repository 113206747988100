.App {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin: 0 auto;
}

.containerApp {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin: 0 auto;
  width: 70%;
}

video:focus {
  outline: none;
}
