.footerMobile {
  display: none;
}

.followSegFooter {
  text-decoration: none;
}

.linkFooter {
  text-decoration: none;
}

@media only screen and (min-width: 1441px) {
  .footer {
    align-items: center;
    justify-content: center;
    background-color: #000f1a;
  }

  .section1Footer {
    height: auto;
    margin: 0 auto;
    padding-top: 4%;
    padding-bottom: 3%;
  }

  .containerSection1Footer {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }

  .infoFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: solid 1px #ffffff;
    width: 67%;
    margin: 0 auto;
    height: 3vw;
    margin-bottom: 3%;
  }

  .emailFooter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30%;
  }

  .emailLogo {
    width: 10%;
    margin-top: 1%;
  }

  .emailLogoImg {
    width: 75%;
  }

  .textContainerEmailFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 58%;
  }

  .textEmailFooter {
    font-size: 1.2vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin: 0;
  }

  .telFooter {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .telTextFooter {
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin: 0;
  }

  .addressFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 40%;
  }

  .textAddressFooter {
    object-fit: contain;
    font-family: IRANSans;
    font-size: 0.85vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    margin: 0;
  }

  .navFooter {
    display: flex;
    flex-direction: row-reverse;
    margin: 0 7.5% 0 0;
    justify-content: center;
    align-items: flex-start;
    width: 57%;
  }

  .segFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 20%;
  }

  .titleFooter {
    object-fit: contain;
    font-family: IRANSans;
    font-size: 1vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    margin-bottom: 10%;
  }

  .linkFooter {
    object-fit: contain;
    font-family: IRANSans;
    font-size: 0.85vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    margin-bottom: 5%;
  }

  .culFollowFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 20%;
  }

  .titleFollowFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 40%;
    margin-bottom: 12.5%;
    margin-right: 2%;
  }

  .textTitleFollowFooter {
    font-size: 1.1vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin-bottom: 0;
  }

  .rowDCulFollowFppter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
  }

  .followSegFooter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin-bottom: 3%;
  }

  .followSegFooterImgContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
  }

  .followSegFooterImg {
    width: 60%;
  }

  .followSegFootertext {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    margin: 5%;
  }

  .linkFollowFooter {
    font-size: 0.9vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0;
  }

  .section2Footer {
    background-color: rgba(12, 68, 67, 0.65);
    direction: ltr;
    height: 4vw;
    justify-content: center;
    align-items: center;
  }

  .containerSection2Footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    padding-top: 1%;
  }

  .rubenoContainerImg {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    margin-right: 2%;
  }

  .rubenoImg {
    width: 15%;
  }

  .textContainerSection2Footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    margin-left: 2%;
  }

  .textSection2Footer {
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0;
  }
}

@media only screen and (max-width: 1440px) {
  .footer {
    align-items: center;
    justify-content: center;
    background-color: #000f1a;
  }

  .section1Footer {
    height: auto;
    margin: 0 auto;
    padding-top: 4%;
    padding-bottom: 3%;
  }

  .containerSection1Footer {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }

  .infoFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: solid 1px #ffffff;
    width: 67%;
    margin: 0 auto;
    height: 3vw;
    margin-bottom: 3%;
  }

  .emailFooter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 35%;
  }

  .emailLogo {
    width: 10%;
    margin-top: 1%;
  }

  .emailLogoImg {
    width: 75%;
  }

  .textContainerEmailFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 58%;
  }

  .textEmailFooter {
    font-size: 1.4vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin: 0;
  }

  .telFooter {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .telTextFooter {
    font-size: 1.2vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin: 0;
  }

  .addressFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 40%;
    margin-right: 2%;
  }

  .textAddressFooter {
    object-fit: contain;
    font-family: IRANSans;
    font-size: 0.9vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    margin: 0;
  }

  .navFooter {
    display: flex;
    flex-direction: row-reverse;
    margin: 0 7.5% 0 0;
    justify-content: center;
    align-items: flex-start;
    width: 57%;
  }

  .segFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 20%;
  }

  .titleFooter {
    object-fit: contain;
    font-family: IRANSans;
    font-size: 1.2vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    margin-bottom: 10%;
  }

  .linkFooter {
    object-fit: contain;
    font-family: IRANSans;
    font-size: 0.9vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    margin-bottom: 5%;
  }

  .culFollowFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 20%;
  }

  .titleFollowFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 40%;
    margin-bottom: 12.5%;
    margin-right: 2%;
  }

  .textTitleFollowFooter {
    font-size: 1.25vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin-bottom: 0;
    white-space: nowrap;
  }

  .rowDCulFollowFppter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
  }

  .followSegFooter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin-bottom: 1%;
  }

  .followSegFooterImgContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
  }

  .followSegFooterImg {
    width: 60%;
  }

  .followSegFootertext {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    margin: 5%;
  }

  .linkFollowFooter {
    font-size: 1.05vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0;
  }

  .section2Footer {
    background-color: rgba(12, 68, 67, 0.65);
    direction: ltr;
    height: 4vw;
    justify-content: center;
    align-items: center;
  }

  .containerSection2Footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    padding-top: 1%;
  }

  .rubenoContainerImg {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 40%;
    margin-right: 2%;
  }

  .rubenoImg {
    width: 20%;
  }

  .textContainerSection2Footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    margin-left: 2%;
  }

  .textSection2Footer {
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    align-items: center;
    justify-content: center;
    background-color: #000f1a;
  }

  .section1Footer {
    height: auto;
    margin: 0 auto;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .containerSection1Footer {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }

  .infoFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: solid 1px #ffffff;
    width: 85%;
    margin: 0 auto;
    height: 5vw;
    margin-bottom: 3%;
  }

  .emailFooter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 35%;
  }

  .emailLogo {
    width: 12.5%;
    margin-top: 2.5%;
  }

  .emailLogoImg {
    width: 75%;
  }

  .textContainerEmailFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 58%;
  }

  .textEmailFooter {
    font-size: 1.8vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin: 0;
  }

  .telFooter {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .telTextFooter {
    font-size: 1.5vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin: 0;
  }

  .addressFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 43%;
    margin-right: 3%;
  }

  .textAddressFooter {
    object-fit: contain;
    font-family: IRANSans;
    font-size: 1.3vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    margin: 0;
  }

  .navFooter {
    display: flex;
    flex-direction: row-reverse;
    margin: 0 auto;
    justify-content: center;
    align-items: flex-start;
    width: 85%;
    margin-left: 3%;
    margin-top: 2.5%;
  }

  .segFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 20%;
  }

  .titleFooter {
    object-fit: contain;
    font-family: IRANSans;
    font-size: 1.8vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    margin-bottom: 16%;
  }

  .linkFooter {
    object-fit: contain;
    font-family: IRANSans;
    font-size: 1.3vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    margin-bottom: 7.5%;
  }

  .culFollowFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 20%;
  }

  .titleFollowFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 40%;
    margin-bottom: 16%;
    margin-right: 6%;
  }

  .textTitleFollowFooter {
    font-size: 1.9vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin-bottom: 0;
    white-space: nowrap;
  }

  .rowDCulFollowFppter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
  }

  .followSegFooter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin-bottom: 0%;
  }

  .followSegFooterImgContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
  }

  .followSegFooterImg {
    width: 80%;
  }

  .followSegFootertext {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    margin: 12%;
  }

  .linkFollowFooter {
    font-size: 1.5vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0;
  }

  .section2Footer {
    background-color: rgba(12, 68, 67, 0.65);
    direction: ltr;
    height: 7vw;
    justify-content: center;
    align-items: center;
  }

  .containerSection2Footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    padding-top: 2%;
  }

  .rubenoContainerImg {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 40%;
    margin-right: 2%;
  }

  .rubenoImg {
    width: 30%;
  }

  .textContainerSection2Footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    margin-left: 2%;
  }

  .textSection2Footer {
    font-size: 1.8vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0;
  }
}

@media only screen and (max-width: 480px) {
  .footer {
    align-items: center;
    justify-content: center;
    background-color: #000f1a;
  }

  .footerNMobile {
    display: none;
  }

  .footerMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .section1Footer {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    padding-top: 6%;
    padding-bottom: 5%;
  }

  .containerSection1Footer {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .infoFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: solid 1px #ffffff;
    width: 100%;
    margin: 0 auto;
    height: 8vw;
    margin-bottom: 3%;
  }

  .emailFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
  }

  .emailLogo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 10%;
    margin-top: 0%;
    margin-right: 5%;
  }

  .emailLogoImg {
    width: 100%;
    margin-left: -0%;
  }

  .textContainerEmailFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
  }

  .textEmailFooter {
    font-size: 3.2vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin: 0;
  }

  .telFooter {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .telTextFooter {
    font-size: 3.2vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin: 0;
  }

  .addressFooter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 95%;
  }

  .textAddressFooter {
    object-fit: contain;
    font-family: IRANSans;
    font-size: 2.7vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    margin: 0;
  }

  .MiddleFooterMobile {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin: 5% 0;
  }

  .navFooter {
    display: flex;
    flex-direction: row-reverse;
    margin: 0 auto;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-left: 0%;
  }

  .segFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 40%;
  }

  .titleFooter {
    object-fit: contain;
    font-family: IRANSans;
    font-size: 3vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    margin-bottom: 17.5%;
  }

  .linkFooter {
    object-fit: contain;
    font-family: IRANSans;
    font-size: 2vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    margin-bottom: 10%;
  }

  .culFollowFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 25%;
  }

  .titleFollowFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 40%;
    margin-bottom: 20%;
  }

  .textTitleFollowFooter {
    font-size: 3.1vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin-bottom: 0;
  }

  .rowDCulFollowFppter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-top: 5%;
  }

  .followSegFooter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin-bottom: 0%;
  }

  .followSegFooterImgContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    margin-left: 0%;
    margin-right: 25%;
  }

  .followSegFooterImg {
    width: 100%;
  }

  .followSegFootertext {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    margin: 23% 0;
  }

  .linkFollowFooter {
    font-size: 2.2vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .section2Footer {
    background-color: rgba(12, 68, 67, 0.65);
    direction: ltr;
    height: 10vw;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .containerSection2Footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 -15% 0 15%;
    padding-top: 3%;
  }

  .rubenoContainerImg {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 30%;
    margin-right: 2%;
  }

  .rubenoImg {
    width: 60%;
  }

  .textContainerSection2Footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 70%;
    margin-left: 2%;
  }

  .textSection2Footer {
    font-size: 2.8vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0;
  }
}
