.titleHeader:hover {
  text-decoration: none;
  color: #ffbf0f;
}

a:hover {
  text-decoration: none;
  color: #ffbf0f;
}

.header {
  z-index: 1;
}

.LogoMobileHeader {
  text-decoration: none;
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  background-color: #006968 !important;
}

@media only screen and (min-width: 1441px) {
  .header {
    text-decoration: none;
    width: 100%;
    height: 10%;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    /* align-items: center; */
    background-color: rgba(0, 15, 26, 0.29);
  }

  .containerHeader {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.5%;
    padding-top: 1.5%;
    background-color: rgba(0, 15, 26, 0.69);
  }

  .logoHeader {
    width: 8%;
  }

  .logoHeaderImg {
    width: 100%;
  }

  .linksHeader {
    display: flex;
    width: 65%;
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-left: 2.5%;
  }

  .titleHeader {
    text-decoration: none;
    cursor: pointer;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 1.1vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    color: #fbfbfb;
    margin: 0 5%;
    height: fit-content;
  }

  .activetitleHeader {
    text-decoration: none;
    cursor: pointer;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 1.1vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    color: #ffbf0f;
    margin: 0 5%;
    height: fit-content;
  }

  .textHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 1.3vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    text-align: right;
    color: #fbfbfb;
  }

  .linkHeader {
    text-decoration: none;
  }

  .MuiPaper-elevation1 {
    background-color: rgba(0, 15, 26, 0.95) !important;
    direction: rtl;
  }

  .headerMobile {
    display: none;
  }

  .profileHeaderContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    width: 10%;
    text-decoration: none;
  }

  .profileHeaderTitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 70%;
    margin-right: -5%;
  }

  .profileHeaderTitleContainer2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .profileHeaderTitle {
    text-decoration: none;
    cursor: pointer;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 1.1vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    color: #c4c4c4;
    margin: 0 5%;
    height: fit-content;
  }

  .profileHeaderTitle:hover {
    color: #fbfbfb;
  }

  .activeprofileHeaderTitle {
    text-decoration: none;
    cursor: pointer;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 1.1vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    color: #ffbf0f;
    margin: 0 5%;
    height: fit-content;
    white-space: nowrap;
  }

  .profileHeaderSvgContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30%;
  }

  .profileHeaderSvg {
    width: 100%;
  }
}

@media only screen and (max-width: 1440px) {
  .header {
    text-decoration: none;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    /* align-items: center; */
    background-color: rgba(0, 15, 26, 0.29);
  }

  .containerHeader {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.5%;
    padding-top: 1%;
    background-color: rgba(0, 15, 26, 0.69);
  }

  .logoHeader {
    width: 8%;
  }

  .logoHeaderImg {
    width: 100%;
  }

  .linksHeader {
    display: flex;
    width: 65%;
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-left: 2.5%;
  }

  .titleHeader {
    text-decoration: none;
    cursor: pointer;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 1.3vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    color: #fbfbfb;
    margin: 0 5%;
    height: fit-content;
  }

  .activetitleHeader {
    text-decoration: none;
    cursor: pointer;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 1.3vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    color: #ffbf0f;
    margin: 0 5%;
    height: fit-content;
  }

  .textHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 1.3vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    text-align: right;
    color: #fbfbfb;
  }

  .linkHeader {
    text-decoration: none;
  }

  .MuiPaper-elevation1 {
    background-color: rgba(0, 15, 26, 0.95) !important;
    direction: rtl;
  }

  .headerMobile {
    display: none;
  }

  .profileHeaderContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    width: 10%;
    text-decoration: none;
  }

  .profileHeaderTitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 70%;
    margin-right: -5%;
  }

  .profileHeaderTitleContainer2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .profileHeaderTitle {
    text-decoration: none;
    cursor: pointer;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 1.3vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    color: #c4c4c4;
    margin: 0 5%;
    height: fit-content;
  }

  .profileHeaderTitle:hover {
    color: #fbfbfb;
  }

  .activeprofileHeaderTitle {
    text-decoration: none;
    cursor: pointer;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 1.3vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    color: #ffbf0f;
    margin: 0 5%;
    height: fit-content;
  }

  .profileHeaderSvgContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30%;
  }

  .profileHeaderSvg {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .header {
    text-decoration: none;
    width: 100%;
    height: 10%;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    /* align-items: center; */
    background-color: rgba(0, 15, 26, 0.29);
  }

  .containerHeader {
    display: none;
  }

  .logoHeader {
    width: 15%;
  }

  .logoHeaderImg {
    width: 100%;
  }

  .linksHeader {
    display: flex;
    width: 70%;
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-left: 2.5%;
  }

  .titleHeader {
    text-decoration: none;
    cursor: pointer;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 2.5vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    color: #fbfbfb;
    margin: 0 5%;
    height: fit-content;
  }

  .activetitleHeader {
    text-decoration: none;
    cursor: pointer;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 2.5vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    color: #ffbf0f;
    margin: 0 5%;
    height: fit-content;
  }

  .textHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 1.3vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    text-align: right;
    color: #fbfbfb;
  }

  .linkHeader {
    text-decoration: none;
  }

  .MuiPaper-elevation1 {
    background-color: rgba(0, 15, 26, 0.95) !important;
    direction: rtl;
  }

  .headerMobile {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 2.5%;
    padding-top: 1.5%;
    background-color: rgba(0, 15, 26, 0.69);
    padding-bottom: 1.5%;
  }

  .hamburgerHeaderMobileImgContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    width: 12.5%;
    margin-bottom: 5%;
    padding-top: 5%;
  }

  .hamburgerHeaderMobileImg {
    width: 20%;
    height: 10%;
  }

  .headerMobileLogoContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 82.5%;
  }

  .headerMobileLogo {
    width: 10%;
    height: 100%;
  }

  .collapseHeaderBG {
    position: absolute;
    width: 67%;
    height: 300vw;
    background-color: #006968;
    color: white;
  }

  .collapseHeader {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 67%;
    height: 300vw;
    margin-top: 300px;
  }

  .collapseHeaderBrandContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 85%;
    border-bottom: 0.5px solid rgba(241, 242, 242, 0.45);
    padding-bottom: 2.5%;
  }

  .collapseHeaderBrandLogoContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 25%;
  }

  .collapseHeaderBrandLogo {
    width: 80%;
  }

  .collapseHeaderBrandTitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 75%;
  }

  .collapseHeaderBrandTitle {
    font-size: 4vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #f1f2f2;
    margin: 0;
  }

  .collapseHeaderTop {
    margin-top: 3% !important;
  }

  .collapseHeaderContentMain {
    direction: rtl;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .collapseHeaderContent {
    direction: rtl;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding-right: 7%;
    color: white;
    border-bottom: 0.5px solid rgba(241, 242, 242, 0.45);
    text-overflow: clip;
    padding-bottom: 5%;
  }

  .collapseHeaderContentLogo {
    border-bottom: 0.5px solid rgba(241, 242, 242, 0.45);
    padding-bottom: 5%;
    margin-top: -1%;
  }

  .collapseHeaderMainTitle {
    font-size: 5vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #f1f2f2;
    margin: 0;
    margin-top: 10%;
  }

  .MuiTypography-root {
    font-family: IRANSans !important;
  }

  .collapseHeaderTitle {
    text-decoration: none;
    cursor: pointer;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 1.1vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    color: #fbfbfb;
  }

  .MuiPaper-elevation1 {
    background-color: #006968 !important;
    border: 1px solid #707070;
  }

  .collapseHeaderProfileContainer {
    margin-top: -1% !important;
  }

  .collapseHeaderProfileSvgContainer {
    min-width: 20px !important;
    width: 40px !important;
  }

  .collapseHeaderProfileSvg {
    width: 80%;
  }

  li {
    list-style-type: none;
  }

  li.collapseHeaderMainTitle {
    list-style-type: none;
  }

  ul.collapseHeaderSubTitleContainer {
    list-style-type: none;
  }

  ul li.collapseHeaderMainTitle::before {
    content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: white; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1.1em; /* Also needed for space (tweak if needed) */
  }

  ul > ul {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    text-decoration: none;
    width: 100%;
    height: 10%;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    /* align-items: center; */
    background-color: rgba(0, 15, 26, 0.29);
  }

  .containerHeader {
    display: none;
  }

  .logoHeader {
    width: 15%;
  }

  .logoHeaderImg {
    width: 100%;
  }

  .linksHeader {
    display: flex;
    width: 70%;
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-left: 2.5%;
  }

  .titleHeader {
    text-decoration: none;
    cursor: pointer;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 2.5vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    color: #fbfbfb;
    margin: 0 5%;
    height: fit-content;
  }

  .activetitleHeader {
    text-decoration: none;
    cursor: pointer;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 2.5vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    color: #ffbf0f;
    margin: 0 5%;
    height: fit-content;
  }

  .textHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 1.3vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    text-align: right;
    color: #fbfbfb;
  }

  .linkHeader {
    text-decoration: none;
  }

  .MuiPaper-elevation1 {
    background-color: rgba(0, 15, 26, 0.95) !important;
    direction: rtl;
  }

  .headerMobile {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 2.5%;
    padding-top: 1.5%;
    background-color: rgba(0, 15, 26, 0.69);
    padding-bottom: 1.5%;
  }

  .hamburgerHeaderMobileImgContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    width: 12.5%;
    margin-bottom: 5%;
    padding-top: 5%;
  }

  .hamburgerHeaderMobileImg {
    width: 35%;
  }

  .headerMobileLogoContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 82.5%;
  }

  .headerMobileLogo {
    width: 12.5%;
    height: 100%;
  }

  .collapseHeaderBG {
    position: absolute;
    width: 67%;
    height: 300vw;
    background-color: #006968;
    color: white;
  }

  .collapseHeader {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 67%;
    height: 300vw;
    margin-top: 300px;
  }

  .collapseHeaderBrandContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 85%;
    border-bottom: 0.5px solid rgba(241, 242, 242, 0.45);
    padding-bottom: 2.5%;
  }

  .collapseHeaderBrandLogoContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 25%;
  }

  .collapseHeaderBrandLogo {
    width: 80%;
  }

  .collapseHeaderBrandTitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 75%;
  }

  .collapseHeaderBrandTitle {
    font-size: 4vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #f1f2f2;
    margin: 0;
  }

  .collapseHeaderTop {
    margin-top: 3% !important;
  }

  .collapseHeaderContentMain {
    direction: rtl;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .collapseHeaderContent {
    direction: rtl;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding-right: 7%;
    color: white;
    border-bottom: 0.5px solid rgba(241, 242, 242, 0.45);
    text-overflow: clip;
    padding-bottom: 5%;
  }

  .collapseHeaderContentLogo {
    border-bottom: 0.5px solid rgba(241, 242, 242, 0.45);
    padding-bottom: 5%;
    margin-top: -1%;
  }

  .collapseHeaderMainTitle {
    font-size: 5vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #f1f2f2;
    margin: 0;
    margin-top: 10%;
  }

  .MuiTypography-root {
    font-family: IRANSans !important;
  }

  .collapseHeaderTitle {
    text-decoration: none;
    cursor: pointer;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 1.1vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    color: #fbfbfb;
  }

  .MuiPaper-elevation1 {
    background-color: #006968 !important;
    border: 1px solid #707070;
  }

  .collapseHeaderProfileContainer {
    margin-top: -1% !important;
  }

  .collapseHeaderProfileSvgContainer {
    min-width: 20px !important;
    width: 40px !important;
  }

  .collapseHeaderProfileSvg {
    width: 80%;
  }

  li {
    list-style-type: none;
  }

  li.collapseHeaderMainTitle {
    list-style-type: none;
  }

  ul.collapseHeaderSubTitleContainer {
    list-style-type: none;
  }

  ul li.collapseHeaderMainTitle::before {
    content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: white; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1.1em; /* Also needed for space (tweak if needed) */
  }

  ul > ul {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .header {
    text-decoration: none;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    /* align-items: center; */
    background-color: rgba(0, 15, 26, 0.29);
  }

  .containerHeader {
    display: none;
  }

  .logoHeader {
    width: 15%;
  }

  .logoHeaderImg {
    width: 100%;
  }

  .linksHeader {
    display: flex;
    width: 70%;
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-left: 2.5%;
  }

  .titleHeader {
    text-decoration: none;
    cursor: pointer;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 2.5vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    color: #fbfbfb;
    margin: 0 5%;
    height: fit-content;
  }

  .activetitleHeader {
    text-decoration: none;
    cursor: pointer;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 2.5vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    color: #ffbf0f;
    margin: 0 5%;
    height: fit-content;
  }

  .textHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 1.3vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    text-align: right;
    color: #fbfbfb;
  }

  .linkHeader {
    text-decoration: none;
  }

  .MuiPaper-elevation1 {
    background-color: rgba(0, 15, 26, 0.95) !important;
    direction: rtl;
  }

  .headerMobile {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 2.5%;
    padding-top: 3.5%;
    background-color: rgba(0, 15, 26, 0.69);
    padding-bottom: 3.5%;
  }

  .hamburgerHeaderMobileImgContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    width: 12.5%;
    margin-bottom: 5%;
    padding-top: 5%;
  }

  .hamburgerHeaderMobileImg {
    width: 40%;
  }

  .headerMobileLogoContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 82.5%;
  }

  .headerMobileLogo {
    width: 15%;
    height: 100%;
  }

  .collapseHeaderBG {
    position: absolute;
    width: 67%;
    height: 300vw;
    background-color: #006968;
    color: white;
  }

  .collapseHeader {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 67%;
    height: 300vw;
    margin-top: 300px;
  }

  .collapseHeaderBrandContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 85%;
    border-bottom: 0.5px solid rgba(241, 242, 242, 0.45);
    padding-bottom: 2.5%;
  }

  .collapseHeaderBrandLogoContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 25%;
  }

  .collapseHeaderBrandLogo {
    width: 80%;
  }

  .collapseHeaderBrandTitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 75%;
  }

  .collapseHeaderBrandTitle {
    font-size: 4vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #f1f2f2;
    margin: 0;
  }

  .collapseHeaderTop {
    margin-top: 3% !important;
  }

  .collapseHeaderContentMain {
    direction: rtl;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .collapseHeaderContent {
    direction: rtl;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding-right: 7%;
    color: white;
    border-bottom: 0.5px solid rgba(241, 242, 242, 0.45);
    text-overflow: clip;
    padding-bottom: 5%;
  }

  .collapseHeaderContentLogo {
    border-bottom: 0.5px solid rgba(241, 242, 242, 0.45);
    padding-bottom: 5%;
    margin-top: -1%;
  }

  .collapseHeaderMainTitle {
    font-size: 5vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #f1f2f2;
    margin: 0;
    margin-top: 10%;
  }

  .MuiTypography-root {
    font-family: IRANSans !important;
  }

  .collapseHeaderTitle {
    text-decoration: none;
    cursor: pointer;
    object-fit: contain;
    font-family: IRANSans;
    font-size: 1.1vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    color: #fbfbfb;
  }

  .MuiPaper-elevation1 {
    background-color: #006968 !important;
    border: 1px solid #707070;
  }

  .collapseHeaderProfileContainer {
    margin-top: -1% !important;
  }

  .collapseHeaderProfileSvgContainer {
    min-width: 20px !important;
    width: 40px !important;
  }

  .collapseHeaderProfileSvg {
    width: 80%;
  }

  li {
    list-style-type: none;
  }

  li.collapseHeaderMainTitle {
    list-style-type: none;
  }

  ul.collapseHeaderSubTitleContainer {
    list-style-type: none;
  }

  ul li.collapseHeaderMainTitle::before {
    content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: white; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1.1em; /* Also needed for space (tweak if needed) */
  }

  ul > ul {
    display: none;
  }
}
